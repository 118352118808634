@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("./fonts/source-sans-pro-400.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("./fonts/source-sans-pro-600.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("./fonts/source-sans-pro-700.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: "Capito";
    font-weight: 300 800;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
    src: url("./fonts/Capito04VAR.woff2") format("woff2");
  }
  @font-face {
    font-family: "Capito";
    font-weight: 300 800;
    font-style: italic;
    font-stretch: normal;
    font-display: swap;
    src: url("./fonts/Capito04VAR-Italic.woff2") format("woff2");
  }

  em {
    @apply font-serif;
  }
}

@layer components {
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    30% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translate(-50%, -100%);
    }
    60% {
      transform: translate(-50%, 12%);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, 0%);
    }
  }

  [data-elm-hot] > :last-child {
    opacity: 0.2;
  }

  .range[type="range"] {
    @apply mt-4 mb-6;
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
    -webkit-tap-highlight-color: transparent;
  }

  .range[type="range"]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  .range[type="range"]::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  /* Special styling for WebKit/Blink */
  .range[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    background: #ffffff;
    cursor: pointer;
    margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    box-shadow: 0px 2px 4px 0px hsla(0, 0%, 0%, 0.1); /* Add cool effects to your sliders! */
  }

  .range[type="range"]:focus-visible::-webkit-slider-thumb {
    outline: 3px solid #b27700;
  }

  /* All the same stuff for Firefox */
  .range[type="range"]::-moz-range-thumb {
    box-shadow: 0px 2px 4px 0px hsla(0, 0%, 0%, 0.1);
    height: 32px;
    width: 32px;
    border-radius: 100%;
    background: #ffffff;
    cursor: pointer;
    border: none;
  }

  /* All the same stuff for IE */
  .range[type="range"]::-ms-thumb {
    box-shadow: 0px 2px 4px 0px hsla(0, 0%, 0%, 0.1);
    height: 32px;
    width: 32px;
    border-radius: 100%;
    background: #ffffff;
    cursor: pointer;
  }

  .range[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: hsla(47, 100%, 82%, 1);
    border-radius: 4px;
  }

  .range[type="range"]::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: hsla(47, 100%, 82%, 1);
    border-radius: 4px;
  }

  .range[type="range"]::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 4px 0;
    color: transparent;
  }
  .range[type="range"]::-ms-fill-lower {
    background: hsla(47, 100%, 82%, 1);
    border-radius: 4px;
  }
  .range[type="range"]:focus::-ms-fill-lower {
    background: hsla(47, 100%, 82%, 1);
  }
  .range[type="range"]::-ms-fill-upper {
    background: hsla(47, 100%, 82%, 1);
    border-radius: 4px;
  }
  .range[type="range"]:focus::-ms-fill-upper {
    background: hsla(47, 100%, 82%, 1);
  }
}

@media (max-width: 1023px) {
  .disable-scrolling {
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
  }
}
